import React from 'react';
import { Link } from 'rebass/styled-components';
import { Text } from '@/components/Text';
import { underlineLinks } from '@/utils/underlineLinks';

const emailJobs = 'mailto:jobs@skalar.studio';
export const jobs = {
  jobsHeader: 'Want to join us?',
  emailJobs,
  jobsApplyButtonText: 'Apply!',
  jobsBody: (
    <Text
      mt={['65px', '65px', 4]}
      fontSize={[3]}
      css={underlineLinks()}
      id="join-us"
    >
      {`
      Haven't found your dream position listed above? Worry not! Are you a developer, producer, data scientist, designer or student looking for an internship? Above all else, we’re always looking for creative people who like solving problems and thinking with data. If you’d like to join our team, send us a note to jobs@skalar.studio.
      `}
      <Link href={`mailto:${emailJobs}`} fontWeight={600}>
        jobs@skalar.studio
      </Link>
      .
    </Text>
  ),
};

export default jobs;
