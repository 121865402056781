import React from 'react';

import { Layout } from '@/components/Layout';
import { Box, Flex, Heading, Link, Button } from 'rebass/styled-components';
import { Text } from '@/components/Text';

import { jobs } from '@/cms/jobs';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from '@/components/ContentWrapper';
import { underlineLinks } from '@/utils/underlineLinks';
import ArrowLink from '@/components/ArrowLink';

// TODO that might be unified with CTA component

export default () => (
  <Layout>
    <Box pt={['95px', '95px', '55px']} mb={[0, 0, '140px']}>
      <ContentWrapper
        maxWidth={1000}
        id="jobs"
        // mt={['90px', '90px', '180px']}
        mb={[3, 3, 0]}
      >
        <Box>
          <Heading
            fontSize={[5, 5, 6]}
            mb={[3]}
            fontWeight={700}
            fontFamily={styledTheme.fonts.body}
          >
            Open positions
          </Heading>

          <Box ml={3}>
            <ArrowLink
              href="/jobs/software-engineer"
              text={
                <strong style={{ fontFamily: 'Archia', fontSize: 24 }}>
                  Software Engineer
                </strong>
              }
            />
            <Box ml={3}>
              <Text>
                <em>Seniority:</em> Mid/Senior
              </Text>
              <Text mt={-1}>
                <em>Technologies:</em> TypeScript, React, GraphQL (Apollo), Node
              </Text>
            </Box>
          </Box>
        </Box>

        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="wrap"
          mt={5}
        >
          <Box width={[1, '400px', '460px', '700px']}>
            <Heading
              fontSize={[5, 5, 6]}
              fontWeight={700}
              fontFamily={styledTheme.fonts.body}
            >
              {jobs.jobsHeader}
            </Heading>
            {jobs.jobsBody}
          </Box>
          <Box mt={[4, 4, 0, 0]}>
            <Button variant="primary" as={Link} href={jobs.emailJobs}>
              {jobs.jobsApplyButtonText}
            </Button>
          </Box>
        </Flex>
      </ContentWrapper>
    </Box>
  </Layout>
);
